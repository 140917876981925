<template>
  <div id="task_form">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-card no-body class="pt-0 mt-0">
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex mt-1">
                <h4 class="mb-0">Task</h4>
              </div>
              <b-row>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <!-- Field: Task Date -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="Title"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Title"
                    label-for="title"
                    label-cols-md="3"
                  >
                    <b-form-input
                      id="title"
                      :state="getValidationState(validationContext)"
                      v-model="taskData.title"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group
                  class="remark required"
                  label="Remark"
                  label-for="remark"
                  label-cols-md="3"
                >
                  <quill-editor v-model="taskData.content" :options="options" />
                </b-form-group>
              </b-col>
            </b-card-body>
            <b-card-body class="invoice-padding form-item-section">
              <hr class="mt-1" />
              <div class="d-flex mt-1">
                <h5 class="mb-2">Task Detail</h5>
              </div>
              <div
                ref="form"
                class="invoice-items repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in taskData.contents"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="3">
                          <b-col cols="12" lg="12">
                            <validation-provider
                              #default="validationContext"
                              name="Record Date"
                              rules="required"
                            >
                              <b-form-group
                                class="record_date required"
                                label="Record Date"
                                label-for="record_date"
                              >
                                <b-form-input
                                  id="record_date"
                                  :state="getValidationState(validationContext)"
                                  v-model="item.record_date"
                                  style="display: none"
                                />
                                <flat-pickr
                                  v-model="item.record_date"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-col>
                        <b-col cols="12" lg="9">
                          <span
                            v-if="item.user"
                            style="position: absolute; right: 15px"
                            ><span v-if="item.user.id != user_id"
                              >[{{ item.user.name }}]</span
                            ></span
                          >
                          <validation-provider
                            #default="validationContext"
                            name="Task Remark"
                            rules="required"
                          >
                            <b-form-group
                              class="task_remark required"
                              label="Task Remark"
                              label-for="task_remark"
                            >
                              <b-form-input
                                id="task_remark"
                                :state="getValidationState(validationContext)"
                                v-model="item.remark"
                                style="display: none"
                              />
                              <quill-editor
                                v-model="item.remark"
                                :disabled="item.create_id != user_id"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <div
                        class="
                          d-flex
                          flex-column
                          justify-content-between
                          border-left
                          py-50
                          px-25
                        "
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index, item.id)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              ></div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add Detail
              </b-button>
            </b-card-body>
            <hr />
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import { heightTransition } from "@core/mixins/ui/transition";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { getUserData } from "@/auth/utils";

export default {
  data() {
    return {
      user_id: getUserData().id,
    };
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("task/updateTask", this.taskData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              if (this.taskData.id == 0) {
                // Return to client list
                //this.hide();
                //	sp1.hk.cmbintl.com
                this.taskData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    hide() {
      this.$router.replace("/tasks");
    },
    redirect(id) {
      this.$router.replace("/tasks/edit/" + id);
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.taskData.contents.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      // this.$nextTick(() => {
      //     this.trAddHeight(this.$refs.row[0].offsetHeight)
      //     setTimeout(() => {
      //         this.$refs.form.style.overflow = null
      //     }, 350)
      // })
    },
    removeItem(index, id) {
      if (this.taskData.contents.length > 1) {
        if (id == 0) {
          this.taskData.contents.splice(index, 1);
        } else {
          this.$swal({
            title: "確認刪除?",
            text: "Your action is final and you will not be able to retrieve the record.",
            showCancelButton: true,
            confirmButtonText: "刪除",
            cancelButtonText: '取消',
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$store
                .dispatch("task/deleteTaskRecord", { id })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  //this.refetchData();
                  this.$emit("refetch-data");
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        }
      } else {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one tasking detail."
        );
      }
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  props: {
    taskData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const button_text = ref("Save");
    const resettaskData = () => {
      props.taskData = JSON.parse(JSON.stringify({}));
    };

    const itemFormBlankItem = {
      id: 0,
      record_date: "",
      remark: "",
      create_id: getUserData().id,
    };

    const options = ref({
      placeholder: "(紀錄未能解決／待處理的問題/進度)",
    });

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettaskData);

    return {
      button_text,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      itemFormBlankItem,
      options,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

