<template>
  <component :is="taskData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="taskData === undefined">
      <h4 class="alert-heading">Error fetching task data</h4>
      <div class="alert-body">
        No task found with this task id. Check
        <b-link class="alert-link" :to="{ name: 'tasks' }"> Task List </b-link>
        for other tasks.
      </div>
    </b-alert>

    <task-edit-tab-information
      v-if="taskData"
      :task-data="taskData"
      @refetch-data="refetchData"
      class="mt-2 pt-75"
    />
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import taskStoreModule from "../taskStoreModule";
// import TaskEditTabAccount from "./TaskEditTabAccount.vue";
import TaskEditTabInformation from "./TaskEditTabInformation.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    // TaskEditTabAccount,
    TaskEditTabInformation,
  },
  setup() {
    const taskData = ref(null);
    const TASK_STORE_MODULE_NAME = "task";

    const task = {
      id: 0,
      title: "WFH",
      remark: "",
      task_date: "",
      time_from: "10:00",
      time_to: "18:00",
      contents: [
        {
          record_date: "",
          remark: "",
          create_id: getUserData().id,
        },
      ],
    };
    // Register module
    if (!store.hasModule(TASK_STORE_MODULE_NAME))
      store.registerModule(TASK_STORE_MODULE_NAME, taskStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_STORE_MODULE_NAME))
        store.unregisterModule(TASK_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "tasks-create") {
        taskData.value = task;
      } else {
        store
          .dispatch(TASK_STORE_MODULE_NAME + "/fetchTask", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            taskData.value = response.data.task;
            console.log(taskData.value.contents.length);
            if (taskData.value.contents.length == 0) {
              taskData.value.contents.push({
                record_date: "",
                remark: "",
                create_id: getUserData().id,
              });
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              taskData.value = undefined;
            }
          });
      }
    };

    refetchData();

    return {
      taskData,
      refetchData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#task_form .ql-editor {
  height: 150px;
}

#task_form .date_from > label::before {
  content: "From";
  position: absolute;
  right: 5%;
}

#task_form .date_to > label::before {
  content: "To";
  position: absolute;
  right: 5%;
}
</style>
